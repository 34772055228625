import { TheIconClosingCross } from '@/components/icons/TheIconClosingCross.tsx'

export interface TheUpgradeInfosModalProps {
  onClose: () => void
}

export function TheUpgradeInfosModal({ onClose }: Readonly<TheUpgradeInfosModalProps>) {
  return (
    <div className="fixed inset-0 z-20 flex items-center justify-center p-4 md:inset-auto md:bottom-10 md:right-20 md:items-start md:justify-end">
      <div className="relative flex w-full max-w-[28rem] flex-col gap-5 rounded-lg border border-solid border-[#DEE2E6] bg-[#FFF] text-center">
        <button onClick={onClose} className="hidden md:absolute md:right-3 md:top-6 md:block">
          <TheIconClosingCross className={'h-6 w-6'} />
        </button>
        <div className="h-4 bg-brightGray" />
        <div className="flex flex-col gap-7 bg-bright px-8 py-6">
          <button className="flex items-center justify-center md:hidden" onClick={onClose}>
            <TheIconClosingCross className={'h-8 w-8'} />
          </button>
          <div className={'space-y-2'}>
            <h3 className="text-xl font-semibold">Olympe : Bientôt une nouvelle étape !</h3>
            <p className="text-sm text-label">
              Pour continuer à vous offrir le meilleur service, Olympe passera bientôt à un modèle payant.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <div className={'space-y-2'}>
              <h4 className="text-base font-semibold">Curieux de voir ce que l&apos;avenir vous réserve ?</h4>
              <p className="text-sm font-normal text-label">
                Restez à l’écoute ! Nous vous tiendrons informés de la disponibilité de nos options d’abonnement très
                bientôt.
              </p>
            </div>
          </div>
          <p className="text-sm font-normal text-label">
            <a
              href={import.meta.env.VITE_OLYMPE_CONTACT_URL}
              target="_blank"
              className="text-sm font-semibold text-label"
              rel="noreferrer"
            >
              Contactez-nous
            </a>{' '}
            pour toute question.
          </p>
        </div>
        <div className="h-4 bg-brightGray" />
      </div>
    </div>
  )
}
