import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import {
  selectSelectedSourcesCollectionLabels,
  selectSourceCollections,
  setSelectedSourceCollectionsLabels,
} from '@/store/sourceCollections/sourceCollectionsSlice.ts'
import { Group } from '@/components/NestedCheckboxList.tsx'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Conversation } from '@/types/conversation.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'

export const useSourceCollections = () => {
  const dispatch = useAppDispatch()
  const sourceCollections = useAppSelector(selectSourceCollections)
  const selectedSourcesCollections = useAppSelector(selectSelectedSourcesCollectionLabels)
  const { isUserConnected } = useCurrentUserHook()
  const [displaySourceFilter, setDisplaySourceFilter] = useState(false)
  const [groups, setGroups] = useState<Group[]>([])
  const { conversationId } = useParams() as {
    conversationId: Conversation['id']
  }

  useEffect(() => {
    setSourceCollections()
  }, [conversationId]) // eslint-disable-line react-hooks/exhaustive-deps

  const setSourceCollections = async () => {
    const groups: Group[] =
      sourceCollections?.map((sourceCollection) => ({
        label: sourceCollection.label,
        items: sourceCollection.collections,
        idAsItem: sourceCollection.idAsSourceCollection,
      })) ?? []
    setGroups(groups)
    const selectedSourceCollections = groups.flatMap((group) => {
      if (!isUserConnected && group.idAsItem === '_user-files-all') {
        return []
      }
      return [
        ...(group.idAsItem ? [group.idAsItem] : []), // Include idAsItem only if it's not undefined
        ...group.items.map((item) => item.id),
      ]
    })
    dispatch(setSelectedSourceCollectionsLabels(selectedSourceCollections))
  }

  const filterRedundantSourceCollectionIds = (groups: Group[], selectedSourceCollections: string[]): string[] => {
    const selectedSet = new Set(selectedSourceCollections)

    // Identify all ids that should be removed: those that are children of a selected "idAsSourceCollection"
    const idsToRemove = new Set<string>()
    groups.forEach((group) => {
      if (group.idAsItem && selectedSet.has(group.idAsItem)) {
        group.items.forEach((item) => idsToRemove.add(item.id))
      }
    })

    // Filter the selectedSourceCollections to exclude ids that are redundant because their parent id is selected
    return selectedSourceCollections.filter((id) => !idsToRemove.has(id))
  }

  const toggleDisplaySourceFilter = () => {
    setDisplaySourceFilter(!displaySourceFilter)
  }

  const handleSelectedSourceCollectionsChange = (newSelectedSourceCollections: string[]) => {
    dispatch(setSelectedSourceCollectionsLabels(newSelectedSourceCollections))
  }

  return {
    groups,
    sourceCollectionIds: filterRedundantSourceCollectionIds(groups, selectedSourcesCollections),
    toggleDisplaySourceFilter,
    handleSelectedSourceCollectionsChange,
  }
}
