import React, { useState } from 'react'
import BaseCheckbox from '../components/atomics/BaseCheckbox.tsx'
import { TheIconCollapse } from './TheIconCollapse.tsx'
import { TheIconExpand } from './icons/TheIconExpand.tsx'
import { openAuthModal } from '@/store/modal/modalsSlice.ts'
import { useAppDispatch } from '@/store/hooks.ts'

interface ListItem {
  id: string
  label: string
}

export interface Group {
  label: string
  items: ListItem[]
  idAsItem?: string // Optional property
}
interface UiGroup extends Group {
  index: number
  collapsed: boolean
}

interface NestedCheckboxListProps {
  groups: Group[]
  selectedItems: string[]
  className: string
  title: string
  onSelectedItemsChange: (selectedItems: string[]) => void
  isUserConnected: boolean
}

export function NestedCheckboxList({
  groups,
  selectedItems,
  title,
  onSelectedItemsChange,
  className,
  isUserConnected,
}: Readonly<NestedCheckboxListProps>) {
  const dispatch = useAppDispatch()

  const indeterminateGroups: number[] = groups
    .map((group, index) => ({
      index,
      children: group.items.map((item) => item.id),
    }))
    .map((group) => ({
      index: group.index,
      children: group.children,
      selectedChildren: group.children.filter((id) => selectedItems.includes(id)),
    }))
    .filter((group) => group.selectedChildren.length > 0 && group.selectedChildren.length < group.children.length)
    .map((group) => group.index)

  const [uiGroups, setUiGroups] = useState(
    groups.map<UiGroup>((group, index) => ({
      ...group,
      index,
      collapsed: true, // Initially collapsed
    })),
  )

  const itemIdsByGroupId: Map<string, string[]> = groups
    .filter((group) => group.idAsItem)
    .map((group) => [group.idAsItem, group.items] as [string, ListItem[]])
    .map(([groupId, items]) => [groupId, items.map((item) => item.id)] as [string, string[]])
    .reduce((accumulator, [groupId, itemIds]) => {
      accumulator.set(groupId, itemIds)
      return accumulator
    }, new Map<string, string[]>())
  const groupIdByItemId: Map<string, string> = groups
    .filter((group) => group.idAsItem)
    .map((group) => [group.idAsItem, group.items] as [string, ListItem[]])
    .flatMap(([groupId, items]) => {
      return items.map((item) => [groupId, item.id])
    })
    .reduce((accumulator, [groupId, itemIds]) => {
      accumulator.set(itemIds, groupId)
      return accumulator
    }, new Map<string, string>())

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    let newSelectedItems: string[]
    if (groupIdByItemId.has(itemId)) {
      const groupId = groupIdByItemId.get(itemId) as string
      if (event.target.checked) {
        // As it is part of a group with a "root id" and it was selected, add this item AND MAYBE the "root id"
        newSelectedItems = [...selectedItems, itemId]
        const allChildrenItemIds = itemIdsByGroupId.get(groupId) ?? []
        if (allChildrenItemIds.every((childrenItemId) => newSelectedItems.includes(childrenItemId))) {
          newSelectedItems.push(groupId)
        }
      } else {
        // As it is part of a group with a "root id" and it was unselected, remove this item AND the "root id"
        newSelectedItems = selectedItems.filter((id) => id !== itemId && id !== groupId)
      }
    } else {
      newSelectedItems = event.target.checked ? [...selectedItems, itemId] : selectedItems.filter((id) => id !== itemId)
    }
    onSelectedItemsChange(newSelectedItems)
  }

  const handleGroupCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, group: Group) => {
    if (group.idAsItem === '_user-files-all' && !isUserConnected) {
      return dispatch(openAuthModal())
    }
    let newSelectedItems: string[]
    const itemIdsFromGroup = group.items.map((item) => item.id)
    if (group.idAsItem) {
      if (event.target.checked) {
        // Group is selected => add "root id" of this group AND add all items of this group to existing selection
        newSelectedItems = [...selectedItems, group.idAsItem, ...itemIdsFromGroup]
      } else {
        // Group is not selected => remove "root id" AND remove all items of this group from existing selection
        newSelectedItems = selectedItems.filter((id) => id !== group.idAsItem && !itemIdsFromGroup.includes(id))
      }
    } else {
      newSelectedItems = event.target.checked
        ? // Group is selected => add all items of this group to existing selection
          [...selectedItems, ...itemIdsFromGroup]
        : // Group is not selected => remove all items of this group from existing selection
          selectedItems.filter((id) => !itemIdsFromGroup.includes(id))
    }
    onSelectedItemsChange(newSelectedItems)
  }

  const renderGroupItems = (group: UiGroup) => {
    if (group.collapsed || group.items.length === 0) {
      return null
    }
    return group.items.map((item) => (
      <div className={`ml-4 rounded px-3 py-1 hover:bg-brightGray`} key={item.id}>
        <label className={'group flex'}>
          <BaseCheckbox
            type="checkbox"
            className={'group-hover:bg-red accent-primary'}
            onChange={(e) => handleCheckboxChange(e, item.id)}
            checked={selectedItems.includes(item.id)}
          />
          <span className={'ml-3 text-sm font-normal text-primary'}>{item.label}</span>
        </label>
      </div>
    ))
  }

  const toggleGroup = (groupIndex: number) => {
    setUiGroups((uiGroups) =>
      [...uiGroups].map((uiGroup) => {
        if (uiGroup.index === groupIndex) {
          return {
            ...uiGroup,
            collapsed: !uiGroup.collapsed,
          }
        }
        return uiGroup
      }),
    )
  }

  const renderGroups = (groups: UiGroup[]) => {
    function getTheIconExpandOrCollapse(group: UiGroup) {
      if (group.items.length === 0) {
        return null
      }
      if (group.collapsed) {
        return <TheIconExpand onClick={() => toggleGroup(group.index)} />
      } else {
        return <TheIconCollapse onClick={() => toggleGroup(group.index)} />
      }
    }

    return groups.map((group) => (
      <div className={'flex flex-col'} key={group.index}>
        <div className={'flex w-full items-center justify-between hover:bg-brightGray'}>
          <label className={'flex px-3 py-1'}>
            <BaseCheckbox
              type="checkbox"
              className={`accent-primary`}
              onChange={(e) => handleGroupCheckboxChange(e, group)}
              checked={
                selectedItems.some((itemId) => group.items.some((item) => item.id === itemId)) ||
                (!!group.idAsItem && selectedItems.includes(group.idAsItem))
              }
              ref={(checkbox) => {
                if (checkbox) {
                  checkbox.indeterminate = indeterminateGroups.includes(group.index)
                }
              }}
            />
            <span className={'ml-3 text-sm font-semibold text-primary'}>{group.label}</span>
          </label>
          <div className={'mr-2 flex h-6 w-6 items-center justify-center'}>{getTheIconExpandOrCollapse(group)}</div>
        </div>
        {renderGroupItems(group)}
      </div>
    ))
  }

  return (
    <div className={className}>
      <h2 className={'px-3 text-sm font-medium text-silver'}>{title}</h2>
      {renderGroups(uiGroups)}
    </div>
  )
}
