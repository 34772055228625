import { useInformationalBanner } from '@/utils/useInformationalBanner.tsx'

export function PromptTutorialContentLayout({ children }: Readonly<{ children: React.ReactNode }>) {
  const { isBannerHidden } = useInformationalBanner()

  return (
    <div
      className={`flex w-full flex-col gap-7 p-4 transition-all duration-500 ease-in-out ${isBannerHidden ? 'pt-8' : 'pt-14'} md:w-4/6`}
    >
      {children}
    </div>
  )
}
