import { TheIconSignout } from '../icons/TheIconSignout.tsx'

type Props = {
  onLogout?: () => void
  isPrivate?: boolean
  isUserConnected: boolean
}

export function TheSidebarFooter({ onLogout, isPrivate = true, isUserConnected }: Readonly<Props>) {
  return (
    <div className={'w-full'}>
      <ul
        className={`border-t-solid flex flex-col gap-4 ${isPrivate && 'border-b'} border-t border-b-label border-t-label px-[0.75rem] pt-5 sm:gap-0 lg:gap-1 lg:pb-5 `}
      >
        <li className="text-[0.75rem] text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_PRIVACY_POLICY_URL}
            target="_blank"
            rel="noreferrer"
          >
            Politique de confidentialité
          </a>
        </li>
        <li className="text-[0.75rem] font-semibold text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_CGU_URL}
            target="_blank"
            rel="noreferrer"
          >
            CGU
          </a>
        </li>
        <li className="text-[0.75rem] font-semibold text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_LEGAL_MENTIONS_URL}
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
        </li>
        <li className="text-[0.75rem] font-semibold text-bright">
          <a
            className="hover:cursor-pointer hover:opacity-70"
            href={import.meta.env.VITE_OLYMPE_CONTACT_URL}
            target="_blank"
            rel="noreferrer"
          >
            Contact
          </a>
        </li>
      </ul>
      {isUserConnected && isPrivate && (
        <button
          className="border-t-solid hidden items-center gap-2 px-[0.75rem] pt-5 hover:cursor-pointer hover:opacity-70 sm:flex"
          onClick={onLogout}
          onKeyDown={onLogout}
        >
          <TheIconSignout className={'h-4 w-4 fill-silver'} />
          <span className="text-xs text-bright">Déconnexion</span>
        </button>
      )}
    </div>
  )
}
