import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { useGreetingUserHook } from './use-greetingUser.hook.tsx'

export function GreetingUser() {
  const { capitalizedName } = useGreetingUserHook()

  return (
    <div className={'flex flex-col items-center justify-center gap-3'}>
      <TheIconOlympeLogoWithText />
      <div className={'flex flex-col items-center justify-center gap-2'}>
        <h1 className={'text-4xl font-semibold text-primary'}>
          Heureux de vous accueillir
          {capitalizedName ? ',' : '.'}
        </h1>
        {capitalizedName && <h3 className={'text-4xl font-semibold text-label'}>{capitalizedName}</h3>}
      </div>
    </div>
  )
}
