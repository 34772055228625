import { DISABLE_BANNER, selectInFormationBanner } from '@/store/modal/modalsSlice.ts'
import { useAppSelector } from '@/store/hooks.ts'

export const useInformationalBanner = () => {
  const showBanner = useAppSelector(selectInFormationBanner)

  return {
    isBannerHidden:
      !import.meta.env.VITE_SHOW_INFORMATIONAL_BANNER ||
      !showBanner.isOpen ||
      localStorage.getItem(DISABLE_BANNER) === 'true',
  }
}
