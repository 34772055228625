import { TheIconOlympeLogoWithText } from './icons/TheIconOlympeLogoWithText.tsx'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATHS } from '../ROUTE_PATHS.ts'
import { BaseButton } from './atomics/BaseButton.tsx'

interface Props {
  readonly tokenIssue: 'invalid' | 'expired'
  readonly email?: string | null
}

export function EmailError({ tokenIssue, email }: Props) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const emailFromParams = searchParams.get('email')
  const errorMessageTranslations: Record<Props['tokenIssue'], string> = {
    invalid: 'Lien invalide...',
    expired: 'Lien expiré...',
  }

  const handleClick = () => {
    if (tokenIssue === 'expired' && email) {
      // VERIFY EXPIRED.
      navigate(ROUTE_PATHS.accountVerificationInfo + `?retry=${email}`)
    } else if (tokenIssue === 'invalid' && email === null) {
      // VERIFY INVALID
      navigate(ROUTE_PATHS.login)
    } else if (tokenIssue === 'expired' && emailFromParams) {
      // RESET EXPIRED
      navigate(ROUTE_PATHS.resetPasswordInfo + `?retry=${emailFromParams}`)
    } else {
      navigate(ROUTE_PATHS.resetPassword)
    }
  }

  const handleLabel = () => {
    return tokenIssue === 'expired' ? 'Renvoyez-moi un lien' : 'Cliquer'
  }

  const contactMail = import.meta.env.VITE_OLYMPE_CONTACT_MAIL
  return (
    <div className={'flex h-screen w-full items-center justify-center text-center'}>
      <div className={'flex w-11/12 flex-col items-center gap-10 sm:w-1/2'}>
        <div className={'flex flex-col items-center gap-6'}>
          <TheIconOlympeLogoWithText />
          <div className={'flex flex-col gap-4'}>
            <h1
              className={'text-xl font-semibold text-primary sm:text-3xl'}
            >{`${errorMessageTranslations[tokenIssue]}`}</h1>
            <div className={'flex flex-col items-center justify-center'}>
              <span className={'text-base text-primary'}>Mais pas de panique !</span>
              <span className={'text-base font-bold text-primary'}>Cliquez simplement sur le bouton ci-dessous :</span>
            </div>
            <span>
              👉 <BaseButton onClick={handleClick} size={'small'} className={'ml-2'} label={handleLabel()} />
            </span>
          </div>
        </div>

        <div className={'flex flex-col'}>
          <span className={'mb-1 text-base font-bold text-label'}>Besoin {"d'un"} coup de main ?</span>
          <p className={'text-sm text-label'}>
            Contactez-nous à{' '}
            <a href={`mailto:${contactMail}`} className={'text-sm font-bold text-label'}>
              contact@olympe.legal
            </a>
            <br /> et notre équipe sera ravie de vous aider.
          </p>
        </div>
      </div>
    </div>
  )
}
