import { sendEmailVerification } from '../../store/auth/useCases/sendEmailVerification.ts'
import { ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { EmailActionLayout } from '../sharedLayouts/EmailActionLayout.tsx'

export function AccountVerificationInfo() {
  const title = 'Vérifiez votre adresse e-mail.'
  const initialMessage =
    "📬 Cher utilisateur, nous avons envoyé un email de vérification à l'adresse que vous avez fournie lors de votre inscription."
  const successMessage = 'Email renvoyé ✅'
  const actionFunction = sendEmailVerification
  const redirectTo = ROUTE_PATHS.login

  return (
    <EmailActionLayout
      title={title}
      initialMessage={initialMessage}
      successMessage={successMessage}
      actionFunction={actionFunction}
      redirectTo={redirectTo}
    />
  )
}
