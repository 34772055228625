import { useAppSelector } from '@/store/hooks'
import { selectFilteredTexts } from '@/store/texts/textsSlice'
import { useState } from 'react'
import { FullHeightLayout } from '../prompt-tutorial/FullHeightLayout'
import LibraryItem from './LibraryItem'
import SearchField from './SearchField'
import { useInformationalBanner } from '@/utils/useInformationalBanner.tsx'

function LibraryPage() {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const texts = useAppSelector(selectFilteredTexts(searchTerm))
  const { isBannerHidden } = useInformationalBanner()

  return (
    <FullHeightLayout className="lg:w-100 bg-secondary">
      <div
        className={`ease-in-out ${isBannerHidden ? 'pt-8' : 'pt-14'} flex h-screen w-full flex-col gap-7 px-4 transition-all duration-500`}
      >
        <div className="flex flex-col gap-5">
          <h1 className={'text-[1.75rem] font-semibold leading-9'}>Bibliothèque Juridique</h1>
          <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
        <div>
          <div className="flex flex-col gap-4">
            {texts && texts.map((text) => <LibraryItem key={text.id} text={text} />)}
            {texts.length === 0 && <p>Aucun texte</p>}
          </div>
        </div>
      </div>
    </FullHeightLayout>
  )
}

export default LibraryPage
