import { selectIsDrawerDisplayed } from '@/store/articles/articlesSlice.ts'
import { selectConversationById, updateCurrentConversationType } from '@/store/conversations/conversationsSlice.ts'
import { createConversation } from '@/store/conversations/useCases/createConversation.ts'
import { deleteFilesInConversation } from '@/store/conversations/useCases/deleteFilesInConversation.ts'
import { listUserConversations } from '@/store/conversations/useCases/listUserConversations.ts'
import { uploadFileInConversation } from '@/store/conversations/useCases/uploadFileInConversation.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { listConversationMessages } from '@/store/messages/useCases/listConversationMessages.ts'
import { Conversation } from '@/types/conversation.ts'
import { useDetectScreenSize } from '@/utils/useDetectScreenSize.ts'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { resetMessages } from '@/store/messages/messagesSlice.ts'

export const useConversationPageHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { conversationId } = useParams() as {
    conversationId: Conversation['id']
  }
  const isLegalWatchDrawerDisplayed = useAppSelector(selectIsDrawerDisplayed)
  const conversation = useAppSelector(selectConversationById(conversationId))
  const [displaySourceFilterMobile, setDisplaySourceFilterMobile] = useState(false)

  const [userInput, setUserInput] = useState('')

  const [isQuestionLoading, setIsQuestionLoading] = useState(false)
  const systemMessageContent =
    conversation?.currentFilesInfos.length === 2
      ? 'Analyse des documents suivants en cours :'
      : 'Analyse du document suivant en cours :'
  const isMobile = useDetectScreenSize()

  const targetRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dispatch(updateCurrentConversationType(conversation?.type ?? 'MESSAGE_ONLY'))
    if (conversationId) dispatch(listConversationMessages(conversationId))
    return () => {
      dispatch(resetMessages())
    }
  }, [conversationId]) // eslint-disable-line react-hooks/exhaustive-deps

  async function navigateToFirstConversationOrCreateOneIfNoneExists() {
    const conversations = await dispatch(listUserConversations()).unwrap()
    if (conversations.length === 0) {
      const newConversation = await dispatch(createConversation({ type: 'MESSAGE_ONLY' })).unwrap()
      navigate(`/conversations/${newConversation.id}`)
    } else {
      navigate(`/conversations/${conversations[0].id}`)
    }
  }
  const handleUpload = async (file: File) => {
    await dispatch(uploadFileInConversation({ conversationId, file, fileName: file.name }))
  }
  const handleStopFileContext = async () => {
    await dispatch(deleteFilesInConversation(conversationId))
  }
  const handleUserInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(e.target.value)
  }
  const handleDisplayFilterMobile = () => {
    setDisplaySourceFilterMobile(!displaySourceFilterMobile)
  }

  const resetUserInput = () => {
    setUserInput('')
  }

  return {
    isMobile,
    conversation,
    targetRef,
    setUserInput,
    userInput,
    isQuestionLoading,
    setIsQuestionLoading,
    displaySourceFilterMobile,
    setDisplaySourceFilterMobile,
    systemMessageContent,
    handleUpload,
    handleStopFileContext,
    handleUserInput,
    handleDisplayFilterMobile,
    isLegalWatchDrawerDisplayed,
    navigateToFirstConversationOrCreateOneIfNoneExists,
    resetUserInput,
  }
}
