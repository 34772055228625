export const ROUTE_PATHS = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  resetPasswordInfo: '/reset-password-info',
  resetPasswordVerification: '/reset-password-verification',
  resetPasswordConfirmation: '/reset-password-confirmation',
  home: '/',
  greetingUser: '/greeting-user',
  conversationSingle: '/conversations/:conversationId',
  documents: '/documents',
  accountVerificationInfo: '/account-verification-info',
  emailVerification: '/email-verification',
  resetPasswordTokenExpiredError: '/reset-password-token-expired-error',
  resetPasswordTokenInvalidError: '/reset-password-token-invalid-error',
  unexpectedError: '/unexpected-error',
  promptTutorial: '/prompt-tutorial',
  sharedConversations: '/shared-conversations/:snapshotId',
  legalWatch: '/legal-watch',
  legalWatchArticle: '/legal-watch/:articleId',
  legalWatchArticleBySlug: '/legal-watch/s/:slug',
  library: '/library',
  libraryDetail: '/library/s/:slug',
  legalWatchFavorites: '/legal-watch/favorites',
} as const

export type RoutePathsItem = (typeof ROUTE_PATHS)[keyof typeof ROUTE_PATHS]
