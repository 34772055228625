import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../configureStore.ts'

interface FilePreviewModalState {
  isOpen: boolean
  pdfData: string
}

interface ModalsState {
  filePreviewModal: FilePreviewModalState
  informationalBanner: { isOpen: boolean }
  authModal: {
    isOpen: boolean
    isSignupFormDisplayed: boolean
  }
  displayUpgradeInfos: {
    isOpen: boolean
  }
}

export const DISABLE_BANNER = 'DISABLE_BANNER'

const initialState: ModalsState = {
  filePreviewModal: {
    isOpen: false,
    pdfData: '',
  },
  informationalBanner: { isOpen: true },
  authModal: { isOpen: false, isSignupFormDisplayed: true },
  displayUpgradeInfos: { isOpen: true },
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    closeUpgradeInfosModal: (state) => {
      state.displayUpgradeInfos.isOpen = false
    },
    toggleFilePreviewModal: (state) => {
      state.filePreviewModal.isOpen = !state.filePreviewModal.isOpen
    },
    toggleAuthModalForm: (state) => {
      state.authModal.isSignupFormDisplayed = !state.authModal.isSignupFormDisplayed
    },
    setPdfData: (state, action) => {
      state.filePreviewModal.pdfData = action.payload
    },
    closeInformationalBanner: (state) => {
      state.informationalBanner.isOpen = false
      localStorage.setItem(DISABLE_BANNER, 'true')
    },
    closeAuthModal: (state) => {
      state.authModal.isOpen = false
    },
    openAuthModal: (state) => {
      state.authModal.isOpen = true
    },
    resetAuthModal: (state) => {
      state.authModal.isSignupFormDisplayed = true
    },
  },
})

export const {
  openAuthModal,
  toggleAuthModalForm,
  resetAuthModal,
  closeAuthModal,
  toggleFilePreviewModal,
  setPdfData,
  closeInformationalBanner,
  closeUpgradeInfosModal,
} = modalsSlice.actions

export const selectFilePreviewModal = (state: RootState) => state.entities.modals.filePreviewModal
export const selectInFormationBanner = (state: RootState) => state.entities.modals.informationalBanner
export const selectIsAuthModalOpen = (state: RootState) => state.entities.modals.authModal.isOpen
export const selectIsSignupFormDisplayed = (state: RootState) => state.entities.modals.authModal.isSignupFormDisplayed
export const selectIsUpgradeInfosModalOpen = (state: RootState) => state.entities.modals.displayUpgradeInfos.isOpen
