import { useAppSelector } from '@/store/hooks.ts'
import { selectCurrentUser } from '@/store/auth/authSlice.ts'

export const useCurrentUserHook = () => {
  const currentUser = useAppSelector(selectCurrentUser)
  const isUserConnected = !!currentUser?.id
  return {
    isUserConnected,
  }
}
