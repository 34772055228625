import { TheFileUploaderMobile } from '../../components/TheFileUploaderMobile.tsx'
import { FileData, FileDataWithExtraColumn } from '@/types/file.ts'
import { TheFilesListMobile } from '../../components/TheFilesListMobile.tsx'
import { ReusableTable, TableColumn } from '../../components/atomics/BaseTable.tsx'
import { TheIconBin } from '../../components/icons/TheIconBin.tsx'
import { DocumentSearchBar } from '../../components/DocumentSearchBar.tsx'
import BaseCheckbox from '../../components/atomics/BaseCheckbox.tsx'
import { BaseModal } from '../../components/atomics/BaseModal.tsx'
import { listUserFiles } from '@/store/files/useCases/listUserFiles.ts'
import { LoaderFunction } from 'react-router-dom'
import { AppStore } from '@/store/configureStore.ts'
import { TheIconTripleDotsVertical } from '../../components/icons/TheIconTripleDotsVertical.tsx'
import { TheIconSuccess } from '../../components/icons/TheIconSuccess.tsx'
import { Tag } from '@/types/tag.ts'
import { BaseSearchInput } from '../../components/atomics/BaseSearchInput.tsx'
import { SortableOptionsWithIcons } from '../../components/SortableOptionsWithIcons.tsx'
import { TheIconArrowUpAndDown } from '../../components/icons/TheIconArrowUpAndDown.tsx'
import { TheIconMoveFiles } from '../../components/icons/TheIconMoveFiles.tsx'
import { SwitchChevronIcons } from '../../components/SwitchChevronIcons.tsx'
import { TheIconUpdate } from '../../components/icons/TheIconUpdate.tsx'
import { ReusableTableRowActions } from '../../components/ReusableTableRowActions.tsx'
import { FilePreviewModal } from '../../components/modals/FilePreviewModal.tsx'
import { useDocumentsHook } from './use.documents.hook.tsx'
import { FullHeightLayout } from '@/pages/prompt-tutorial/FullHeightLayout.tsx'
import { DocumentsPageHeaderMobile } from '@/components/ds/DocumentsPageHeaderMobile.tsx'
import { DocumentsFolderSection } from '@/components/ds/DocumentsFolderSection.tsx'
import { DocumentsPageHeader } from '@/components/ds/DocumentsPageHeader.tsx'
import { RenameDocumentModalContainer } from '@/components/modals/containers/RenameDocumentModalContainer.tsx'
import { TheUploadInformationsCardContainer } from '@/components/TheUploadInformationsCardContainer.tsx'
import { DocumentsFolderSectionMobile } from '@/components/DocumentsFolderSectionMobile.tsx'
import { listTags } from '@/store/tags/useCases/listTags.ts'
import { WarningModalContainer } from '@/components/modals/containers/WarningModalContainer.tsx'
import { CreateDocumentModalContainer } from '@/components/modals/containers/CreateDocumentModalContainer.tsx'
import { useInformationalBanner } from '@/utils/useInformationalBanner.tsx'

export const documentsLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async () => {
    await store.dispatch(listUserFiles())
    await store.dispatch(listTags())
    return null
  }

export function Documents() {
  const {
    handleApplyTags,
    handleDeleteDocuments,
    deleteFileByFileId,
    toggleRowSelection,
    selectedItem,
    handleSetSelectedItem,
    expendablesSections,
    setSelectedItem,
    isBulkModalVisible,
    toggleBulkModal,
    toggleAction,
    setIsActive,
    getFontStyleBasedOnCurrentRef,
    file,
    toggleSingleFileActionsModal,
    setFilterKey,
    filterKey,
    selectedRows,
    handleFilePreview,
    date,
    extension,
    alphabeticOrder,
    tags,
    files,
    handleDeleteAllDocumentsOnMobile,
    isRowModalVisible,
    toggleRowModal,
    toggleRenameFileModal,
    handleFileInputChange,
    filterFiles,
    handleClearSearchValue,
    searchValue,
    emptyArrayMessage,
    filteredData,
    handleUpdateDocument,
    handleSelectAll,
    deleteFileByRowIndex,
    isBulkTagSelectVisible,
    setIsBulkTagSelectVisible,
    currentTagName,
  } = useDocumentsHook()
  const { isBannerHidden } = useInformationalBanner()

  // Table data structure
  const columns: TableColumn<FileDataWithExtraColumn>[] = [
    {
      key: 'select',
      render: (item: FileData) => {
        return <BaseCheckbox checked={selectedRows.includes(item)} onChange={() => toggleRowSelection(item)} />
      },
      title: '',
      sortable: false,
      editable: false,
    },
    {
      key: 'title',
      title: 'Nom du document',
      sortable: true,
      editable: true,
    },
    {
      key: 'createdAt',
      title: "Date d'ajout",
      sortable: true,
      editable: false,
    },
    {
      key: 'extension',
      title: 'Format',
      sortable: true,
      editable: false,
    },
    {
      key: 'action',
      sortable: false,
      editable: false,
      title: 'Action',
      render: (item: FileData) => {
        return (
          <ReusableTableRowActions
            displayFileAction={() => handleFilePreview(item)}
            deleteFileAction={() => deleteFileByFileId({ ...item, size: '' })}
            displayMovingFileAction={() => handleSetSelectedItem(item)}
            item={item}
            selectedItem={selectedItem}
            toggleModalAction={() => setSelectedItem(null)}
            selectTagAction={(tag) => handleApplyTags(tag, [item])}
          />
        )
      },
    },
  ]

  // Renders
  const renderSortingAndFilteringModal = () => {
    const sortActionStatus = expendablesSections.isSortActionsSectionOpened ? 'up' : 'down'
    const moveActionStatus = expendablesSections.isMoveActionsSectionOpened ? 'up' : 'down'
    return (
      <BaseModal
        contentVisible={isBulkModalVisible}
        onClick={toggleBulkModal}
        positionClass={'right-0 left-0 bottom-0'}
        animationClass={'translate-y-full'}
        styleBasedOnScreenSize={'w-full bg-bright shadow-softOutline'}
      >
        <ul className={'flex flex-col gap-4 px-3 pb-7 pt-6'}>
          <li>
            <SortableOptionsWithIcons
              onClick={() => toggleAction('isSortActionsSectionOpened')}
              titleLeftIcon={<TheIconArrowUpAndDown />}
              titleRightIcon={
                <div className={'px-2'}>
                  <SwitchChevronIcons status={sortActionStatus} />
                </div>
              }
              title={'Trier par'}
            >
              <ul
                className={`pl-12 pr-4 ${expendablesSections.isSortActionsSectionOpened ? 'flex flex-col' : 'hidden'}`}
              >
                <li>
                  <button
                    id={'Date'}
                    ref={date}
                    onClick={setIsActive}
                    className={`flex w-full items-center justify-between py-2 text-base ${getFontStyleBasedOnCurrentRef(
                      date,
                    )}`}
                  >
                    Plus récent
                    {date?.current?.id === filterKey && (
                      <TheIconSuccess width="18" height="18" className={'fill-primary'} />
                    )}
                  </button>
                </li>
                <li>
                  <button
                    id={'Extension'}
                    ref={extension}
                    onClick={setIsActive}
                    className={`flex w-full items-center justify-between py-2 text-base ${getFontStyleBasedOnCurrentRef(
                      extension,
                    )}`}
                  >
                    Format
                    {extension?.current?.id === filterKey && (
                      <TheIconSuccess width="18" height="18" className={'fill-primary'} />
                    )}
                  </button>
                </li>
                <li>
                  <button
                    id={'Alphabetic-Order'}
                    ref={alphabeticOrder}
                    onClick={setIsActive}
                    className={`flex w-full items-center justify-between py-2 text-base ${getFontStyleBasedOnCurrentRef(
                      alphabeticOrder,
                    )}`}
                  >
                    Ordre alphabétique
                    {alphabeticOrder?.current?.id === filterKey && (
                      <TheIconSuccess width="18" height="18" className={'fill-primary'} />
                    )}
                  </button>
                </li>
              </ul>
            </SortableOptionsWithIcons>
          </li>
          <li>
            <SortableOptionsWithIcons
              onClick={() => toggleAction('isMoveActionsSectionOpened')}
              titleLeftIcon={<TheIconMoveFiles width="16" height="16" />}
              titleRightIcon={
                <div className={'px-2'}>
                  <SwitchChevronIcons status={moveActionStatus} />
                </div>
              }
              title={'Tout déplacer vers'}
            >
              <ul className={` pl-12 ${expendablesSections.isMoveActionsSectionOpened ? 'flex flex-col' : 'hidden'}`}>
                {tags.map((tag: Tag) => {
                  return (
                    <li key={tag.id}>
                      <button
                        className={`flex items-center justify-between py-2 text-base leading-5 text-label`}
                        onClick={async () => {
                          await handleApplyTags(tag, files)
                          toggleBulkModal()
                        }}
                      >
                        {tag.name}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </SortableOptionsWithIcons>
          </li>
          <div className={'w-6/6 h-0.5 bg-primary'} />
          <button
            onClick={handleDeleteAllDocumentsOnMobile}
            className={'flex gap-2 px-3 py-2 text-base font-semibold leading-5 text-primary'}
          >
            <TheIconBin className={'w-4'} />
            Tout supprimer
          </button>
        </ul>
      </BaseModal>
    )
  }

  const renderSingleFileActionsModal = () => {
    const moveActionStatus = expendablesSections.isMoveActionsSectionOpened ? 'up' : 'down'
    return (
      <BaseModal
        contentVisible={isRowModalVisible}
        onClick={toggleRowModal}
        positionClass={'right-0 bottom-0'}
        animationClass={'translate-y-full'}
        styleBasedOnScreenSize={'w-full bg-bright shadow-softOutline'}
      >
        <ul className={`flex flex-col px-4 py-8`}>
          <li>
            <button
              className={'flex w-full items-center gap-2 px-3.5 py-2'}
              onClick={() => {
                toggleRowModal()
                toggleRenameFileModal()
              }}
            >
              <TheIconUpdate width="16" height="17" className={'fill-primary'} />
              Renommer
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                deleteFileByFileId(file)
              }}
              className={'flex w-full items-center gap-2 px-3.5 py-2'}
            >
              <TheIconBin width="16" height="19" className={'fill-primary'} />
              Supprimer
            </button>
          </li>
          <li>
            <SortableOptionsWithIcons
              onClick={() => toggleAction('isMoveActionsSectionOpened')}
              titleLeftIcon={<TheIconMoveFiles width="16" height="16" />}
              titleRightIcon={
                <div className={'px-2'}>
                  <SwitchChevronIcons status={moveActionStatus} />
                </div>
              }
              title={'Déplacer vers'}
            >
              <ul className={` pl-12 ${expendablesSections.isMoveActionsSectionOpened ? 'flex flex-col' : 'hidden'}`}>
                {tags.map((tag: Tag) => {
                  return (
                    <li key={tag.id}>
                      <button
                        className={`flex items-center justify-between py-2 text-base leading-5 text-label`}
                        onClick={() => {
                          handleApplyTags(tag, [file])
                          toggleRowModal()
                        }}
                      >
                        {tag.name}
                      </button>
                    </li>
                  )
                })}
              </ul>
            </SortableOptionsWithIcons>
          </li>
        </ul>
      </BaseModal>
    )
  }

  return (
    <FullHeightLayout>
      {/* Mobile version */}
      <div className={'flex h-full flex-col sm:hidden'}>
        <DocumentsPageHeaderMobile
          title={'Base de connaissance'}
          subtitle={
            'Vous pouvez importer et retrouver ici les documents que vous souhaitez ajouter à votre base de connaissance.'
          }
        />
        <TheFileUploaderMobile handleFileInputChange={handleFileInputChange} />
        <TheUploadInformationsCardContainer />
        <div className={'flex basis-full flex-col gap-4 bg-[#EDF1F5] px-4 pt-6'}>
          <DocumentsFolderSectionMobile />
          <div className={'flex items-center justify-between'}>
            <h3 className={'text-xl font-semibold leading-6 text-mintGreen'}>{currentTagName}</h3>
            <TheIconTripleDotsVertical
              onClick={toggleBulkModal}
              width="21"
              height="36"
              className={'inline-block fill-label hover:fill-label'}
            />
          </div>
          <BaseSearchInput
            placeholder="Rechercher un document"
            onChange={filterFiles}
            onClear={handleClearSearchValue}
            value={searchValue}
          />
          <TheFilesListMobile
            toggleSingleFileActionsModal={toggleSingleFileActionsModal}
            emptyArrayMessage={emptyArrayMessage}
            files={filteredData}
            filterKey={filterKey}
            setFilterKey={setFilterKey}
          />
        </div>
      </div>

      {/* Desktop version */}
      <div
        className={`hidden h-full p-4 transition-all duration-500 ease-in-out ${isBannerHidden ? 'pt-8' : 'pt-14'} sm:flex sm:flex-col sm:gap-11`}
      >
        <DocumentsPageHeader
          title={'Base de connaissance'}
          subtitle={
            'Vous pouvez déposer et retrouver ici les documents que vous souhaitez ajouter à votre base de connaissance.'
          }
        />
        <DocumentsFolderSection />
        <div className={'flex flex-col gap-5 pb-4'}>
          <DocumentSearchBar
            responsiveStyle={'hidden sm:flex sm:flex-col gap-4 lg:flex-row lg:items-center'}
            title={currentTagName}
            searchValue={searchValue}
            filterData={filterFiles}
            handleClearSearchValue={handleClearSearchValue}
            handleFileInputChange={handleFileInputChange}
          />
          <TheUploadInformationsCardContainer />

          <ReusableTable
            handleUpdateCell={handleUpdateDocument}
            selectedRowsLength={selectedRows.length}
            handleSelectAll={handleSelectAll}
            handleExtraCell={deleteFileByRowIndex}
            handleDeleteAll={handleDeleteDocuments}
            filteredData={filteredData}
            emptyArrayMessage={emptyArrayMessage}
            columns={columns}
            defaultSorting={{ columnKey: 'createdAt', order: 'desc' }}
            isBulkTagSelectVisible={isBulkTagSelectVisible}
            onClick={() => {
              setIsBulkTagSelectVisible(!isBulkTagSelectVisible)
            }}
            handleBulkTagSelectOption={(tag) => handleApplyTags(tag, selectedRows as FileData[])}
          />
        </div>
      </div>

      <FilePreviewModal />
      <RenameDocumentModalContainer />
      <CreateDocumentModalContainer />
      <WarningModalContainer />
      {renderSingleFileActionsModal()}
      {renderSortingAndFilteringModal()}
    </FullHeightLayout>
  )
}
