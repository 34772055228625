import { nanoid } from '@reduxjs/toolkit'
import { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Conversation } from '../types/conversation.ts'
import { Message } from '../types/message.ts'
import { SourceReadingInfos } from '../types/source.ts'
import { TEMPORARY_AI_ID } from '../utils/CONVERSATION_CONSTANTS.ts'
import { useDetectScreenSize } from '../utils/useDetectScreenSize.ts'
import AvatarIcon from './AvatarIcon.tsx'
import { TheAddedDocumentTag } from './TheAddedDocumentTag.tsx'
import { TheCalendlyModal } from './TheCalendlyModal.tsx'
import { TheIconThumbDown } from './TheIconThumbDown.tsx'
import { TheIconThumbUp } from './TheIconThumbUp.tsx'
import { TheSourcesListItem } from './TheSourcesListItem.tsx'
import { BaseButton } from './atomics/BaseButton.tsx'
import { BaseModal } from './atomics/BaseModal.tsx'
import { TheIconCalendar } from './icons/TheIconCalendar.tsx'
import { useMessageFeedBack } from '@/components/utils/useMessageFeedBack.ts'

type Props = { message: Message; conversation: Conversation }

export function TheMessageItem(props: Readonly<Props>) {
  const [isSourcesListVisible, setIsSourcesListVisible] = useState(false)
  const isMobile = useDetectScreenSize()
  const calendlyCtaRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { showAppointmentButton, handleThumbDownUpClick, handleThumbDownClick } = useMessageFeedBack()

  useEffect(() => {
    if (calendlyCtaRef.current && props.message.feedback && props.message.feedback === 'THUMBS_DOWN') {
      calendlyCtaRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [props.message.feedback])

  const wrapperClass = () => {
    return props.message.type === 'HUMAN' ? 'flex-col items-end sm:flex-row-reverse' : 'flex-col sm:flex-row'
  }

  const contentClass = () => {
    return props.message.type === 'HUMAN' ? 'text-right' : 'text-left prose'
  }

  const ctaDisplayClasses = props.message.id === TEMPORARY_AI_ID ? 'hidden' : ''

  const mobileAnimationClass = 'translate-y-full'
  const desktopAnimationClass = 'translate-x-full'
  const getAnimationClass = () => {
    return isMobile ? mobileAnimationClass : desktopAnimationClass
  }
  const getPositionClass = () => {
    return isMobile ? 'bottom-0' : 'right-0'
  }
  const toggleSourcesList = () => {
    setIsSourcesListVisible(!isSourcesListVisible)
  }

  const renderCTASection = () => {
    return (
      <>
        <BaseButton
          className={'w-full sm:w-fit'}
          label={`Voir les ${props.message.sources.length} sources officielles`}
          onClick={toggleSourcesList}
          disabled={props.message.sources.length < 1}
        />
        <div className="mb-2 mt-3 flex items-center justify-center gap-[0.5rem] md:justify-start">
          <p className="text-center text-base text-label sm:text-left sm:text-sm">Satisfait de la réponse ?</p>
          <button
            onClick={() =>
              handleThumbDownUpClick('THUMBS_DOWN', props.message.feedback, props.conversation.id, props.message.id)
            }
            className={'group'}
          >
            <TheIconThumbUp
              className={'group-hover:stroke-primary'}
              isSelected={props.message.feedback === 'THUMBS_UP'}
            />
          </button>
          <button
            onClick={() =>
              handleThumbDownClick('THUMBS_UP', props.message.feedback, props.conversation.id, props.message.id)
            }
            className={'group'}
          >
            <TheIconThumbDown
              className={'group-hover:stroke-primary'}
              isSelected={props.message.feedback === 'THUMBS_DOWN'}
            />
          </button>
        </div>
        {showAppointmentButton && (
          <div className={'flex flex-col gap-3 sm:pl-9'}>
            <p className={`mb-2 mt-5 text-left text-sm sm:text-left`}>
              Souhaitez-vous contacter nos spécialistes pour mieux répondre à votre question ?
            </p>
            <BaseButton
              onClick={() => setIsModalOpen(!isModalOpen)}
              label="Prendre RDV"
              color="quaternary"
              icon={<TheIconCalendar />}
              className="w-1/2 self-center rounded-3xl border border-primary px-1.5 py-0.5 text-sm font-light hover:text-sm hover:font-light sm:self-start sm:rounded-md"
            />
          </div>
        )}
        {isModalOpen ? <TheCalendlyModal closeModal={() => setIsModalOpen(!isModalOpen)} /> : ''}
      </>
    )
  }

  const calculateContainerStyleBasedOnScreenSize = () => {
    return isMobile ? 'z-10 h-4/5 w-screen rounded-t-lg bg-brightGray shadow-softOutline' : 'h-full w-96 bg-brightGray'
  }

  return (
    <>
      {props.message.type === 'SYSTEM' ? (
        <div
          className={`mr-0 flex w-full rounded-lg bg-brightGray py-3 sm:justify-normal lg:ml-auto lg:w-3/4 lg:gap-4 ${wrapperClass()}`}
        >
          <div className="m-1 mr-2 h-fit w-fit px-4 py-3">
            <AvatarIcon type={props.message.type} />
          </div>

          <div className={'flex flex-col gap-4 text-center'}>
            <ReactMarkdown className={`${contentClass()} text-center text-sm leading-8 sm:text-left`}>
              {props.message.content}
            </ReactMarkdown>
            <div className={'flex flex-col justify-between gap-2 lg:flex-row lg:gap-0'}>
              {!!props.conversation?.currentFilesInfos.length &&
                props.conversation?.currentFilesInfos.map((fileInfos) => (
                  <TheAddedDocumentTag
                    key={nanoid()}
                    fileInfos={{
                      ...fileInfos,
                      uploadStatus: 'UPLOADED',
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={'my-4'}>
          <div className={`flex gap-2 py-4 sm:gap-4 ${wrapperClass()}`}>
            <div className="m-0 h-fit w-fit p-0">{<AvatarIcon type={props.message.type} />}</div>
            <div className="w-full">
              <ReactMarkdown className={`${contentClass()} text-base leading-6`}>{props.message.content}</ReactMarkdown>
            </div>
          </div>
          <div className={`w-full sm:mb-4 sm:ml-14 sm:w-fit ${ctaDisplayClasses}`}>
            {props.message.type !== 'HUMAN' && renderCTASection()}
          </div>
          <BaseModal
            contentVisible={isSourcesListVisible}
            onClick={toggleSourcesList}
            positionClass={getPositionClass()}
            styleBasedOnScreenSize={calculateContainerStyleBasedOnScreenSize()}
            animationClass={getAnimationClass()}
          >
            <div className={'my-5 flex flex-col items-center justify-center'}>
              {props.message.sources.map((source: SourceReadingInfos) => (
                <TheSourcesListItem
                  key={props.message.sources.indexOf(source)}
                  sourceNumber={props.message.sources.indexOf(source) + 1}
                  source={source}
                />
              ))}
            </div>
          </BaseModal>
        </div>
      )}
    </>
  )
}
