import { useAppDispatch } from '@/store/hooks.ts'
import { closeInformationalBanner } from '@/store/modal/modalsSlice.ts'
import { InformationalBanner } from './InformationalBanner'
import { useInformationalBanner } from '@/utils/useInformationalBanner.tsx'

export function InformationalBannerContainer() {
  const dispatch = useAppDispatch()
  const { isBannerHidden } = useInformationalBanner()
  if (isBannerHidden) return

  return (
    <InformationalBanner
      onCloseBanner={() => {
        dispatch(closeInformationalBanner())
      }}
    >
      <p className="text-sm leading-5 text-gray-900">
        <strong className="font-semibold">Information : </strong>il est possible que certaines conversations antérieures
        au 25/04 aient disparu de votre historique. Nous travaillons activement à résoudre ce problème. Merci pour votre
        compréhension.
      </p>
    </InformationalBanner>
  )
}
