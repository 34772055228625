import { MouseEventHandler } from 'react'
import { InlineWidget } from 'react-calendly'

type Props = { closeModal: MouseEventHandler }

export function TheCalendlyModal({ closeModal }: Readonly<Props>) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative h-fit w-10/12 rounded-lg bg-transparent sm:p-8">
        <button
          className="absolute -right-[40px] -top-[50px] m-4 rounded-full bg-primary p-2 text-white sm:right-0 sm:top-0"
          onClick={closeModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="text-center">
          <InlineWidget
            url="https://calendly.com/fxcao/rdv-question-protection-des-donnees-avec-olympe"
            styles={{ height: '650px', minWidth: '320px' }}
          />
        </div>
      </div>
    </div>
  )
}
